import React, { useEffect, useState } from 'react';
import { fireStore } from '../firebase';
import { collection, query, where, getDocs, getDoc, doc } from "firebase/firestore";
import { auth } from '../firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = useState(null);
    const [userInfo, setUserInfo] = useState(null)
    const [dbKey, setDbKey] = useState(null)

    useEffect(() => {
        auth.onAuthStateChanged(setCurrentUser)
    }, []);

    useEffect(async () => {
        if (currentUser) {
            console.log(currentUser.uid);
            const q = query(collection(fireStore, "users"), where("uids", "array-contains", currentUser.uid));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async(res) => {
                console.log(res.id)
                setDbKey(res.id)
                const subColRef = doc(fireStore, "users", res.id, "users", currentUser.uid);
                const snap = await getDoc(subColRef)
                setUserInfo(snap.data())
            })
            // const subColRef = doc(fireStore, "users", "region two", "users", currentUser.uid);
            // const snap = await getDoc(subColRef)
            // setUserInfo(snap.data())
        }
    }, [currentUser])

    // useEffect(async () => {
    //     if (dbKey) {
    //         const subColRef = doc(fireStore, "users", dbKey, "users", currentUser.uid);
    //         const snap = await getDoc(subColRef)
    //         setUserInfo(snap.data())
    //     }
    // }, [dbKey])


    let logout = () => {
        auth.signOut();
        setCurrentUser(null)
        setUserInfo(null)
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser, 
                userInfo,
                logout, 
                dbKey
            }}
            class='context' >
            {children}
        </AuthContext.Provider>
    );
};