import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom' 
import { getAuth } from "firebase/auth";
import { Layout, Drawer } from 'antd'
import {
    MenuUnfoldOutlined
  } from '@ant-design/icons';
import { AuthContext } from '../../context/Context';

function Header(props) {

    const navigate = useNavigate();

    const { logout, userInfo, dbKey } = useContext(AuthContext);
    const [visible, setVisible] = useState(false)

    const openMenu = () => {
        setVisible(true)
      }
    
      const onClose = () => {
        setVisible(false)
      }

      const { Header } = Layout;

      let itemClicked = (route) => {
        navigate(route)
        setVisible(false)
      }

    return (
        <div className="header">
            <Layout>

                <Header style={{ backgroundColor: 'gray', textAlign: 'center' }} className="header">
                    <span style={{float: 'left', cursor: 'pointer'}} onClick={openMenu}><MenuUnfoldOutlined /><b> MENU</b></span>
                    <span style={{ fontSize: '2.4em' }}><b>{dbKey} WISE Charter Committee</b></span>
                    <span style={{float: 'right'}}><b>{userInfo ? userInfo.name : null }</b></span>
                </Header>

                <Drawer
                    title='Menu'
                    placement='left'
                    closable={false}
                    onClose={onClose}
                    visible={visible}
                >
                    <p onClick={() => logout()}>Log Out</p>
                    <hr />
                    <p className='linkText' onClick={() => itemClicked('/ethicsTable')}>Grids</p>
                    <p className='linkText' onClick={() => itemClicked('/org-board')}>Org Board</p>
                    <p className='linkText' onClick={() => itemClicked('/stats')}>Stats</p>
                    <p className='linkText' onClick={() => itemClicked('/hatting')}>Hatting</p>
                    <p className='linkText' onClick={() => itemClicked('/references')}>References</p>
                    <p className='linkText' onClick={() => itemClicked('/calender')}>Events Calender</p>

                </Drawer>

            </Layout>

        </div>
    );
}

export default Header; 
 