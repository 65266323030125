import React, { useState, useEffect, lazy, Suspense } from 'react';
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'
import { AuthProvider } from './context/Context';
import { auth } from '../src/firebase';
import {  BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from 'antd';

import Header from './components/header/Header'
import './App.less';

//const { Header } = Layout;
const Login = lazy(() => import('./components/login/Login'))
const UserHome = lazy(() => import('./components/userHome/UserHome'))
const Reports = lazy(() => import('./components/reports/Reports'))
const OrgBoard = lazy(() => import('./components/orgBoard/OrgBoard'))
const Stats = lazy(() => import('./components/stats/Stats'))
const Hatting = lazy(() => import('./components/hatting/HattingWrapper'))
const References = lazy(() => import('./components/references/ReferenceSectionWrapper'))
const Calender = lazy(() => import('./components/calender/Calender'))
const FourZeroFour = lazy(() => import('./components/404/FourZeroFour'))

function App() {     

  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    auth.onAuthStateChanged((newUser) => {
      setUser(newUser)
      setLoading(false)
    })
  }, [])

  function PrivateRoute({ children }) {
    return user ? children : <Navigate to="/login" />;
  }

  return (
      <AuthProvider>
          <Suspense fallback={<div>loadingScreen</div>}>
              <Router>
                    { user ? <Header /> : null }
                  {loading ? <div>loading....</div> :
                      <Routes>
                          <Route path='/' element={
                              <PrivateRoute>
                                  <UserHome />
                              </PrivateRoute>
                          } />
                          <Route path='/ethicsTable' element={
                              <PrivateRoute>
                                  <UserHome />
                              </PrivateRoute>
                          } />
                          <Route path='/disputesTable' element={
                              <PrivateRoute>
                                  <UserHome />
                              </PrivateRoute>
                          } />
                          <Route path='/reports/:type/:gridId' element={
                              <PrivateRoute>
                                  <Reports />
                              </PrivateRoute>
                          } />
                          <Route path='/:table/reports/:type/:gridId' element={
                              <PrivateRoute>
                                  <Reports />
                              </PrivateRoute>
                          } />
                          <Route path='/org-board' element={
                              <PrivateRoute>
                                  <OrgBoard />
                              </PrivateRoute>
                          } />
                          <Route path='/stats' element={
                              <PrivateRoute>
                                  <Stats />
                              </PrivateRoute>
                          } />
                          <Route path='/hatting' element={
                              <PrivateRoute>
                                  <Hatting />
                              </PrivateRoute>
                          } />
                          <Route path='references' element={
                              <PrivateRoute>
                                  <References />
                              </PrivateRoute>
                          } />
                          <Route path='hat-packs' element={
                              <PrivateRoute>
                                  <References />
                              </PrivateRoute>
                          } />
                          <Route path='/calender' element={
                              <PrivateRoute>
                                  <Calender />
                              </PrivateRoute>
                          } />
                          <Route path='/login' element={user ? <Navigate replace to="/ethicsTable" /> : <Login />} />
                          <Route path='*' element={<FourZeroFour />} />
                      </Routes>
                  }
              </Router>
          </Suspense>
      </AuthProvider>
  );
}

export default DragDropContext(HTML5Backend)(App)